import React from "react";
import styled from "styled-components";

const AwardDescription = styled.div`
  line-height: 1.2em;
  margin-bottom: 1.3em;

  &::last-child {
    margin-bottom: 0;
  }

  span {
    display: block;

    &.awardOrganization {
      color: #2f524c;
      font-weight: bold;
      font-style: italic;
      font-size: 0.9em;
    }
  }

  h4 {
    &.awardTitle {
      color: black;
      font-weight: bold;
    }
  }

  ul {
    margin-left: 1em;
  }

  li {
    ::marker {
      color: #569087;
    }
  }
`;

const Awards = () => {
  return (
    <article>
      <h3>HONOR AWARDS</h3>
      <AwardDescription>
        <h4 className="awardTitle">Founder's Award (04/2020)</h4>
        <span className="awardOrganization">Bentley Systems, Inc</span>
        <ul>
          <li>
            Award granted annually by the company to a small number of its
            highest performers.
          </li>
        </ul>
      </AwardDescription>

      <AwardDescription>
        <h4 className="awardTitle">Founder's Award (04/2019)</h4>
        <span className="awardOrganization">Bentley Systems, Inc</span>
        <ul>
          <li>
            Award granted annually by the company to a small number of its
            highest performers.
          </li>
        </ul>
      </AwardDescription>
    </article>
  );
};

export default Awards;
